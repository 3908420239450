<template>
  <div class="map">
    <div class="item" v-for="item in lists" :key="item.id">
      <div class="url">
        <router-link :to="item.link_url">{{item.cate_name}}</router-link>
        <template v-if="item.children.lenght!==0">
          <div class="citem" v-for="e in item.children" :key="e.id">
            <router-link :to="e.link_url">{{e.cate_name}}</router-link>
          </div>
        </template>
      </div>
    </div>
    <div class="item" v-for="item in forumLists" :key="item.id">
      <div class="url">
        <router-link :to="item.link_url+'/'+item.id">{{item.cate_name}}</router-link>
      </div>
    </div>
    <div class="item" v-for="item in plates" :key="item.id">
      <div class="url">
        <router-link :to="item.link_url">{{item.cate_name}}</router-link>
        <template v-if="item.children.lenght!==0">
          <div class="citem" v-for="children in item.children" :key="children.id">
            <router-link v-if="children.id!==54" :to="children.link_url + '/' + children.id" >{{children.cate_name}}</router-link>
            <a v-else :href="children.link_url " target="_blank">{{children.cate_name}}</a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lists: [],
      forumLists: [],
      plates: [],
    };
  },
  mounted() {
    this.menu();
    this.forumMenu();
    this.plate();
  },
  methods: {
    menu() {
      return this.$request(Apis.mun.MUNS).then(data => {
        let lists = [];
        data.data.data.forEach((item, index) => {
          if (item.id !== 5 && item.id !== 6) {
            lists.push(item);
          }
        });
        this.lists = lists;

        // this.onRouterChange(this.$route);
      });
    },
    forumMenu() {
      return this.$request(Apis.mun.FORUMMUNS).then(data => {
        let lists = [];
        data.data.data.forEach((item, index) => {
          if (item.id !== 24 && item.id !== 36 && item.id !== 41 && item.id !== 42 && item.id !== 43) {
            lists.push(item);
          }
        });
        this.forumLists = lists;

        // console.log(this.forumLists);
        // this.onRouterChangeForumMenu(this.$route);
      });
    },
    plate() {
      this.$request(Apis.mun.PLATE).then(data => {
        this.plates = data.data.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.map {
  width: 50%;
  margin: auto;
  .item {
    .url {
      a {
        color: #ccc;
        font-size: 18px;
        margin-right: 10px;
      }
      display: flex;
      .citem {
        a {
          font-size: 16px;
        }
      }
    }
  }
}
</style>