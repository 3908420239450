var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map" },
    [
      _vm._l(_vm.lists, function(item) {
        return _c("div", { key: item.id, staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "url" },
            [
              _c("router-link", { attrs: { to: item.link_url } }, [
                _vm._v(_vm._s(item.cate_name))
              ]),
              item.children.lenght !== 0
                ? _vm._l(item.children, function(e) {
                    return _c(
                      "div",
                      { key: e.id, staticClass: "citem" },
                      [
                        _c("router-link", { attrs: { to: e.link_url } }, [
                          _vm._v(_vm._s(e.cate_name))
                        ])
                      ],
                      1
                    )
                  })
                : _vm._e()
            ],
            2
          )
        ])
      }),
      _vm._l(_vm.forumLists, function(item) {
        return _c("div", { key: item.id, staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "url" },
            [
              _c(
                "router-link",
                { attrs: { to: item.link_url + "/" + item.id } },
                [_vm._v(_vm._s(item.cate_name))]
              )
            ],
            1
          )
        ])
      }),
      _vm._l(_vm.plates, function(item) {
        return _c("div", { key: item.id, staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "url" },
            [
              _c("router-link", { attrs: { to: item.link_url } }, [
                _vm._v(_vm._s(item.cate_name))
              ]),
              item.children.lenght !== 0
                ? _vm._l(item.children, function(children) {
                    return _c(
                      "div",
                      { key: children.id, staticClass: "citem" },
                      [
                        children.id !== 54
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: children.link_url + "/" + children.id
                                }
                              },
                              [_vm._v(_vm._s(children.cate_name))]
                            )
                          : _c(
                              "a",
                              {
                                attrs: {
                                  href: children.link_url,
                                  target: "_blank"
                                }
                              },
                              [_vm._v(_vm._s(children.cate_name))]
                            )
                      ],
                      1
                    )
                  })
                : _vm._e()
            ],
            2
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }